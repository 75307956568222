.room-container{
    background-color: #ecffdf;
}
.heading-room {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .heading-room h2 {
    font-size: 44px;
    margin-bottom: 30px;
    position: relative;
    text-transform: uppercase;
    color: #28a745;
  }

  .heading-room p {
    text-align: center;
    font: 16px / 24px Rubik;
    letter-spacing: 0;
    color: #264552;
    max-width: 550px;
    margin: 0 auto;
  }

.four-icon-container{
    width: 900px;
    margin: 50px auto;
    display: flex;
    gap: 180px;

}

.four-icon{
    text-align: center;
}

.room-card-container{
    display: flex;
    max-width: 1140px;
    margin: 0  auto;
    justify-content:space-between ;
    margin-bottom: 100px;
    
}

.room-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin: 20px;
    max-width: 400px;
    background-color: #f9f9f9;
  }
  
  .room-card img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .room-card h2 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  
  .room-card .description {
    margin-bottom: 10px;
    font-size: 18px;
    text-align: justify;
  }
  
  .room-card .features {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-evenly;
    text-align: center;
  }
  
  .room-card .feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    text-align: center;
  }
  
  .room-card .feature-item .icon {
    margin-right: 10px;
    font-size: 24px; /* Increase the size of the icon */
  }
 
  
  .room-card .feature-item .text {
    display: block; /* Ensure text appears below the icon */
  }
  
  .room-card .book-now-btn {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    padding: 10px 24px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .room-card .book-now-btn:hover {
    background-color: #45a049;
  }
  