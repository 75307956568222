.about-section {
    background-color: #f8f9fa; /* Adjust background color */
    padding: 100px 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .heading {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .heading h2 {
    font-size: 44px;
    margin-bottom: 100px;
    position: relative;
    text-transform: uppercase;
    color: #28a745;
  }
  
  .heading .line {
    position: absolute;
    width: 50px;
    height: 2px;
    background-color: #333; /* Adjust line color */
  }
  
  .heading .line:first-child {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .heading .line:last-child {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
  }
  
  .image img {
    max-width: 600px; 
    max-height: 400px; 
    height: auto; 
    border-radius: 10px;
  }
  
  .text {
    width: calc(50% - 20px); /* Adjust width as needed */
  }
  
  .text h3 {
    font-size: 30px;
    margin-bottom: 20px;
    color: #28a745;
    text-transform: uppercase;
  }
  
  .text p {
    font-size: 16px;
    line-height: 1.6;
  }

  .resort-section {
    background-color: #28a745;
    margin-top: 100px;
    max-width: 1550px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 450px;
    font: 700 100px/100% DM Sans, -apple-system, Roboto, Helvetica, sans-serif;
    border-bottom: solid 1px black;
    
  }
 