.navbar {
    background-color: #28a745;
    padding: 10px 0;
  }
  
  .navbar-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .navbar-logo {
    width: 100px; /* Adjust the width according to your logo */
    height: auto;
  }
  
  .navbar-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    text-transform: uppercase;
  }
  
  .navbar-item {
    margin-right: 30px;
  }
  
  .navbar-link {
    text-decoration: none;
    color: white;
  }
  .navbar-link:hover{
    text-decoration: none;
    color: #0a3b15;
  }
  
  .navbar-link:hover {
    text-decoration: underline;
  }
  
  .navbar-button {
    border-radius: 50%;
    
    background-color: #333;
    border: none;
    color: white;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
    font-size: 25px; /* Increase font size */
    
  }
  
  .navbar-button:hover {
    background-color:#0e581f ;
    transform: translateY(-1px); /* Add slight upward movement on hover */
    
  }
