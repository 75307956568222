.contact-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    text-transform: uppercase;
  }
  
  .contact-content h1 {
    font-size: 62px;
    margin-bottom: 20px;
  }
  
  .contact-content p {
    font-size: 22px;
    margin-bottom: 1dvb;
  }
  
  
.contact-us {
    padding: 20px;
    margin-bottom: 100px;
  }
  

  .contact-heading {
    text-align: center;
   
  }
  
  .contact-heading h2{
    font-size: 44px;
    margin-bottom: 10px;
    margin-top: 100px;
    position: relative;
    text-transform: uppercase;
    color: #28a745;
  }

  .contact-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
 

  .contact-text {
   margin: 20px;
   display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 20px;
  }

  .contact- text p{
    font-size: 20px;
  }
  
 