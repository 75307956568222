.heading {
    text-align: center;
    margin-bottom: 40px;
  }

  .heading h2 {
    font-size: 44px;
    margin-bottom: 100px;
    position: relative;
    text-transform: uppercase;
    color: #28a745;
  }

  .gallery {
    padding: 20px;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three images per row */
    grid-gap: 20px; /* Gap between grid items */
  }
  
  .item {
    overflow: hidden; /* Hide overflowing content */
  }
  
  .item img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
  }