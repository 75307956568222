.second-navbar {
    background-color: #f8f9fa; /* Adjust background color */
    padding: 10px 0;
  }
  
  .second-navbar-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .left-section {
    display: flex;
    align-items: center;
  }
  
  .phone-number {
    margin-right: 20px;
  }
  
  .right-section {
    display: flex;
  }
  
  .social-icon {
    font-size: 20px;
    color: #333; /* Adjust icon color */
    margin-left: 10px;
    text-decoration: none;
  }
  
  .social-icon:hover {
    color: #007bff; /* Change color on hover */
  }