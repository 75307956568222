.hero {
  position: relative;
  height: 600px; /* Adjust height as needed */
  background-size: cover;
  background-position: center;
  transition: background-image 0.5s ease; /* Add transition for background image */
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay color with opacity for background image */
}

.arrow-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.5); /* Adjust button background color and opacity */
  border: none;
  border-radius: 50%;
  font-size: 24px;
  color: #333; /* Adjust button text color */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.arrow-button:hover {
  background-color: #16802f; /* Adjust button background color and opacity on hover */
}

.arrow-button.left {
  left: 20px;
}

.arrow-button.right {
  right: 20px;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.hero-heading {
  font-size: 62px;
  margin-bottom: 20px;
}

.hero-subheading {
  font-size: 22px;
  margin-bottom: 40px;
}

.book-now-button {
  background-color: white;
  color: #333; /* Button text color */
  padding: 12px 24px;
  font-size: 18px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.5s ease; /* Adjust transition time to 0.5s */
}

.book-now-button:hover {
  background-color: #28a745; /* Change background color to green on hover */
  color: white; /* Change text color to white on hover */
}