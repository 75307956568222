.rights-reserved-container {
    background-color: #28a745;
    width: 100vw;
    display: flex;
    flex-direction: row;
    padding:30px 100px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    font-size: var(--h6-small-14px-regular-size);
    color: var(--text-color-white);
    font-family: var(--font-poppins);
  }
  
  .rights-reserved-container-items {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }

  .plans-pricing3 {
    position: relative;
  }